import React, { useState } from "react";
import { Link } from "react-router-dom";
import '../App.css';
import { useTranslation } from "react-i18next";
import { useQuery } from '@tanstack/react-query';
import api from '../api';
import logo from '../images/logo.png';
import { FaGlobe } from 'react-icons/fa';

function Navbar({ user, onLogout }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const { data: currentUser } = useQuery(
    ["currentUser"],
    () =>
      api
        .get("/auth/user")
        .then((res) => res.data)
        .catch(() => null),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  // Construire l'URL de connexion
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const REDIRECT_URI = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
  const RESPONSE_TYPE = "code";
  const SCOPE = "identify email guilds";
  const loginUrl = `https://discord.com/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${encodeURIComponent(
    SCOPE
  )}`;

  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);

  const toggleLanguageDropdown = () => {
    setShowLanguageDropdown(!showLanguageDropdown);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setShowLanguageDropdown(false);
  };

  return (
    <header className="header">
      <nav className="navbar bg-indigo-700 text-white p-4">
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/" className="logo flex items-center">
            <img src={logo} alt="Logo" className="h-20 w-auto mr-2" />
          </Link>
          <div className="hidden md:flex space-x-4 items-center">
            <Link
              to="/"
              className="text-gray-300 hover:text-white transition duration-200"
            >
              Accueil
            </Link>
            <Link
              to="/info"
              className="text-gray-300 hover:text-white transition duration-200"
            >
              Info
            </Link>
            {currentUser && (
              <Link
                to="/profile"
                className="text-gray-300 hover:text-white transition duration-200"
              >
                Profil
              </Link>
            )}
            {user && (
              <>
                <Link
                  to="/dashboard"
                  className="text-gray-300 hover:text-white transition duration-200"
                >
                  Tableau de Bord
                </Link>
                {user.isModerator && (
                  <Link
                    to="/moderator"
                    className="text-gray-300 hover:text-white transition duration-200"
                  >
                    Modérateur
                  </Link>
                )}
                {user.isAdmin && (
                  <Link
                    to="/admin"
                    className="text-gray-300 hover:text-white transition duration-200"
                  >
                    Admin
                  </Link>
                )}
              </>
            )}
            {user ? (
              <button
                onClick={onLogout}
                className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded transition duration-200"
              >
                Se déconnecter
              </button>
            ) : (
              <a
                href={loginUrl}
                className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded transition duration-200"
              >
                Se connecter
              </a>
            )}
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-gray-300 hover:text-white focus:outline-none"
            >
              <svg
                className="h-6 w-6 fill-current"
                viewBox="0 0 24 24"
              >
                {isMobileMenuOpen ? (
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.3 5.71a1 1 0 00-1.41-1.42L12 9.17 7.11 4.29a1 1 0 00-1.41 1.42L10.59 12l-4.89 4.88a1 1 0 001.41 1.42L12 14.83l4.88 4.89a1 1 0 001.42-1.42L13.41 12l4.89-4.88z"
                  />
                ) : (
                  <path
                    fillRule="evenodd"
                    d="M4 5h16v2H4V5zm0 6h16v2H4v-2zm0 6h16v2H4v-2z"
                  />
                )}
              </svg>
            </button>
          </div>
          <div className="flex items-center">
            <button
              onClick={toggleLanguageDropdown}
              className="mx-2 px-3 py-1 bg-gray-700 text-white rounded hover:bg-gray-600 focus:outline-none"
            >
              <FaGlobe className="text-2xl" />
            </button>

            {showLanguageDropdown && (
              <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-50">
                <button
                  onClick={() => changeLanguage('fr')}
                  className="block px-4 py-2 text-sm text-white hover:bg-gray-700 w-full text-left"
                >
                  Français
                </button>
                <button
                  onClick={() => changeLanguage('en')}
                  className="block px-4 py-2 text-sm text-white hover:bg-gray-700 w-full text-left"
                >
                  English
                </button>
                {/* Ajoutez d'autres langues si nécessaire */}
              </div>
            )}
          </div>
        </div>
      </nav>
      {isMobileMenuOpen && (
        <div className="md:hidden px-2 pt-2 pb-3 space-y-1">
          <Link
            to="/"
            className="block text-gray-300 hover:text-white px-3 py-2 rounded-md"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Accueil
          </Link>
          <Link
            to="/info"
            className="block text-gray-300 hover:text-white px-3 py-2 rounded-md"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            Info
          </Link>
          {currentUser && (
            <Link
              to="/profile"
              className="block text-gray-300 hover:text-white px-3 py-2 rounded-md"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Profil
            </Link>
          )}
          {user && (
            <>
              <Link
                to="/dashboard"
                className="block text-gray-300 hover:text-white px-3 py-2 rounded-md"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Tableau de Bord
              </Link>
              {user.isModerator && (
                <Link
                  to="/moderator"
                  className="block text-gray-300 hover:text-white px-3 py-2 rounded-md"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Modérateur
                </Link>
              )}
              {user.isAdmin && (
                <Link
                  to="/admin"
                  className="block text-gray-300 hover:text-white px-3 py-2 rounded-md"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Admin
                </Link>
              )}
            </>
          )}
          {user ? (
            <>
              <button
                onClick={() => {
                  onLogout();
                  setIsMobileMenuOpen(false);
                }}
                className="block w-full text-left bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded transition duration-200"
              >
                Se déconnecter
              </button>
            </>
          ) : (
            <a
              href={loginUrl}
              className="block bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded transition duration-200"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Se connecter
            </a>
          )}
        </div>
      )}
    </header>
  );
}

export default Navbar;
