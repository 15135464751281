import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./components/Home";
import Dashboard from "./images/Dashboard";
import AdminPanel from "./components/AdminPanel";
import ModeratorsManagement from "./components/ModeratorsManagement";
import ServerList from "./components/ServerList";
import UsersList from "./components/UsersList";
import AdminLogsPage from "./components/AdminLogsPage";
import NotFound from "./components/NotFound";
import Login from "./components/Login";
import ServerDetails from "./components/ServerDetails";
import api from "./api";
import Navbar from "./components/Navbar";
import ModeratorPanel from "./components/ModeratorPanel";
import InfoPage from "./components/InfoPage";
import Footer from "./components/Footer";
import Profile from "./components/Profile";
import AdminRoute from "./components/AdminRoute";
import AdminLayout from "./components/AdminLayout";
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import './index.css';
import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function App() {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    api
      .get("/auth/user")
      .then((response) => {
        setUser(response.data);
        setLoadingUser(false);
      })
      .catch(() => {
        setLoadingUser(false);
      });
  }, []);

  useEffect(() => {
    if (user) {
      api.post("/auth/visit").then(() => {});
    }
  }, [user]);

  const onLogout = () => {
    api.post("/auth/logout").then(() => {
      setUser(null);
    });
  };

  if (loadingUser) {
    return <div>Chargement...</div>;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Navbar user={user} onLogout={onLogout} />
          <div className="flex-1 bg-gray-900 text-white">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/servers/:id" element={<ServerDetails />} />
              <Route
                path="/dashboard"
                element={user ? <Dashboard user={user} /> : <Navigate to="/login" />}
              />
              <Route
                path="/admin/*"
                element={
                  user && user.isAdmin ? (
                    <AdminLayout>
                      <Routes>
                        <Route path="/" element={<AdminPanel />} />
                        <Route
                          path="/moderators-management"
                          element={
                            <AdminRoute user={user}>
                              <ModeratorsManagement />
                            </AdminRoute>
                          }
                        />
                        <Route
                          path="/servers-list"
                          element={
                            <AdminRoute user={user}>
                              <ServerList isAdmin={true} />
                            </AdminRoute>
                          }
                        />
                        <Route
                          path="/users-list"
                          element={
                            <AdminRoute user={user}>
                              <UsersList />
                            </AdminRoute>
                          }
                        />
                        <Route
                          path="/logs"
                          element={
                            <AdminRoute user={user}>
                              <AdminLogsPage />
                            </AdminRoute>
                          }
                        />
                      </Routes>
                    </AdminLayout>
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/moderator"
                element={
                  user && user.isModerator ? (
                    <ModeratorPanel />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/info" element={<InfoPage />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
