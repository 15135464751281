import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import api from "../api";
import { useQuery } from "@tanstack/react-query";
import Select from 'react-select';
import '../App.css';
import Flag from "react-world-flags";
import {
  FaUsers,
  FaUserFriends,
  FaMicrophoneAlt,
  FaPenFancy,
  FaHeadset,
  FaStar,
  FaStarHalfAlt,
  FaRegStar,
  FaArrowUp,
  FaArrowDown,
  FaEquals,
  FaHeart,
  FaRegHeart,
} from 'react-icons/fa';
import Tooltip from './Tooltip';
import moment from 'moment';
import 'moment/locale/fr';
import { formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';

function ServerCard({
  server,
  handleDelete,
  handleUpdate,
  isDashboard = false,
  isAdmin = false,
  refreshFavorites,
  isFavorite,
  userPermissions = {},
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [categories, setCategories] = useState(server.categories || []);
  const [tags, setTags] = useState(server.tags || []);
  const [description, setDescription] = useState(server.description || "");
  const [countries, setCountries] = useState(server.countries || []);
  const [favorite, setFavorite] = useState(isFavorite);
  const [currentServer, setCurrentServer] = useState(server);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const descriptionRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [tagInput, setTagInput] = useState('');
  const [currentUser, setCurrentUser] = useState(null);

  // Mémoriser categoryOptions
  const categoryOptions = useMemo(() => [
    { value: "Gaming", label: "Gaming" },
    { value: "Musique", label: "Musique" },
    { value: "Technologie", label: "Technologie" },
    { value: "Art", label: "Art" },
    { value: "Anime/Manga", label: "Anime/Manga" },
    { value: "Roleplay", label: "Roleplay" },
    { value: "Communauté", label: "Communauté" },
    { value: "Éducation", label: "Éducation" },
    { value: "Sports", label: "Sports" },
    { value: "Memes", label: "Memes" },
    { value: "Programmation", label: "Programmation" },
    { value: "Voyage", label: "Voyage" },
    { value: "Fitness", label: "Fitness" },
    { value: "Cinéma", label: "Cinéma" },
    { value: "Livres", label: "Livres" },
    { value: "Science", label: "Science" },
    { value: "Support", label: "Support" },
    { value: "NSFW", label: "NSFW" },
    { value: "Streaming", label: "Streaming" },
    { value: "Crypto", label: "Crypto/Blockchain" },
    { value: "Cuisine", label: "Cuisine" },
    { value: "Photographie", label: "Photographie" },
    { value: "Esports", label: "Esports" },
    { value: "Animaux", label: "Animaux" },
    { value: "Développement Personnel", label: "Développement Personnel" },
    { value: "Mode", label: "Mode/Beauté" },
    { value: "Histoire", label: "Histoire" },
    { value: "Philosophie", label: "Philosophie" },
    { value: "Autre", label: "Autre" }
  ], []);

  // Mémoriser countryOptions
  const countryOptions = useMemo(() => [
    { value: "AF", label: "Afghanistan" },
    { value: "ZA", label: "Afrique du Sud" },
    { value: "AL", label: "Albanie" },
    { value: "DZ", label: "Algérie" },
    { value: "DE", label: "Allemagne" },
    { value: "AD", label: "Andorre" },
    { value: "AO", label: "Angola" },
    { value: "AG", label: "Antigua-et-Barbuda" },
    { value: "SA", label: "Arabie Saoudite" },
    { value: "AR", label: "Argentine" },
    { value: "AM", label: "Arménie" },
    { value: "AU", label: "Australie" },
    { value: "AT", label: "Autriche" },
    { value: "AZ", label: "Azerbaïdjan" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Bahreïn" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbade" },
    { value: "BY", label: "Biélorussie" },
    { value: "BE", label: "Belgique" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Bénin" },
    { value: "BT", label: "Bhoutan" },
    { value: "BO", label: "Bolivie" },
    { value: "BA", label: "Bosnie-Herzégovine" },
    { value: "BW", label: "Botswana" },
    { value: "BR", label: "Brésil" },
    { value: "BN", label: "Brunei" },
    { value: "BG", label: "Bulgarie" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "KH", label: "Cambodge" },
    { value: "CM", label: "Cameroun" },
    { value: "CA", label: "Canada" },
    { value: "CV", label: "Cap-Vert" },
    { value: "CL", label: "Chili" },
    { value: "CN", label: "Chine" },
    { value: "CY", label: "Chypre" },
    { value: "CO", label: "Colombie" },
    { value: "KM", label: "Comores" },
    { value: "CG", label: "Congo-Brazzaville" },
    { value: "CD", label: "Congo-Kinshasa" },
    { value: "CR", label: "Costa Rica" },
    { value: "CI", label: "Côte d'Ivoire" },
    { value: "HR", label: "Croatie" },
    { value: "CU", label: "Cuba" },
    { value: "DK", label: "Danemark" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominique" },
    { value: "EG", label: "Égypte" },
    { value: "AE", label: "Émirats Arabes Unis" },
    { value: "EC", label: "Équateur" },
    { value: "ES", label: "Espagne" },
    { value: "EE", label: "Estonie" },
    { value: "US", label: "États-Unis" },
    { value: "FJ", label: "Fidji" },
    { value: "FI", label: "Finlande" },
    { value: "FR", label: "France" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambie" },
    { value: "GE", label: "Géorgie" },
    { value: "GH", label: "Ghana" },
    { value: "GR", label: "Grèce" },
    { value: "GD", label: "Grenade" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GT", label: "Guatemala" },
    { value: "GN", label: "Guinée" },
    { value: "GW", label: "Guinée-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "GF", label: "Guyane française" },
    { value: "HT", label: "Haïti" },
    { value: "HN", label: "Honduras" },
    { value: "HU", label: "Hongrie" },
    { value: "HK", label: "Hong Kong" },
    { value: "IN", label: "Inde" },
    { value: "ID", label: "Indonésie" },
    { value: "IE", label: "Irlande" },
    { value: "IS", label: "Islande" },
    { value: "IL", label: "Israël" },
    { value: "IT", label: "Italie" },
    { value: "JM", label: "Jamaïque" },
    { value: "JP", label: "Japon" },
    { value: "JO", label: "Jordanie" },
    { value: "KZ", label: "Kazakhstan" },
    { value: "KE", label: "Kenya" },
    { value: "RE", label: "La Réunion" },
    { value: "LV", label: "Lettonie" },
    { value: "LS", label: "Lesotho" },
    { value: "LB", label: "Liban" },
    { value: "LR", label: "Libéria" },
    { value: "LY", label: "Libye" },
    { value: "LT", label: "Lituanie" },
    { value: "LU", label: "Luxembourg" },
    { value: "MO", label: "Macao" },
    { value: "MK", label: "Macédoine" },
    { value: "MG", label: "Madagascar" },
    { value: "MY", label: "Malaisie" },
    { value: "MW", label: "Malawi" },
    { value: "MV", label: "Maldives" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malte" },
    { value: "MA", label: "Maroc" },
    { value: "MQ", label: "Martinique" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mexique" },
    { value: "MD", label: "Moldavie" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolie" },
    { value: "ME", label: "Monténégro" },
    { value: "MZ", label: "Mozambique" },
    { value: "NA", label: "Namibie" },
    { value: "NP", label: "Népal" },
    { value: "NI", label: "Nicaragua" },
    { value: "NC", label: "Nouvelle-Calédonie" },
    { value: "NL", label: "Pays-Bas" },
    { value: "PE", label: "Pérou" },
    { value: "PH", label: "Philippines" },
    { value: "PL", label: "Pologne" },
    { value: "PF", label: "Polynésie française" },
    { value: "PT", label: "Portugal" },
    { value: "QA", label: "Qatar" },
    { value: "RO", label: "Roumanie" },
    { value: "GB", label: "Royaume-Uni" },
    { value: "RU", label: "Russie" },
    { value: "BL", label: "Saint-Barthélemy" },
    { value: "MF", label: "Saint-Martin" },
    { value: "PM", label: "Saint-Pierre-et-Miquelon" },
    { value: "RS", label: "Serbie" },
    { value: "SK", label: "Slovaquie" },
    { value: "SI", label: "Slovénie" },
    { value: "SE", label: "Suède" },
    { value: "CH", label: "Suisse" },
    { value: "TW", label: "Taïwan" },
    { value: "TH", label: "Thaïlande" },
    { value: "TN", label: "Tunisie" },
    { value: "TR", label: "Turquie" },
    { value: "UA", label: "Ukraine" },
    { value: "UY", label: "Uruguay" },
    { value: "VN", label: "Vietnam" },
    { value: "VE", label: "Venezuela" },
    { value: "WF", label: "Wallis-et-Futuna" },
    { value: "ZM", label: "Zambie" },
    { value: "ZW", label: "Zimbabwe" }
  ], []);

  const maxCategories = 3;
  const maxTags = 3;
  const maxDescription = 500;
  const maxCharactersForTags = 15;

  const imageUrl = server.imageUrl
    ? `https://cdn.discordapp.com/icons/${server.id}/${server.imageUrl}.${server.imageUrl.startsWith("a_") ? "gif" : "png"}?size=300&timestamp=${Date.now()}`
    : null;

  const handleJoinServer = async () => {
    if (server.inviteLink) {
      try {
        await api.post("/logs", {
          action: "join_server",
          details: { serverId: server.id },
        });
      } catch (error) {
        console.error("Erreur lors de l'envoi du log :", error);
      }
      window.open(server.inviteLink, "_blank");
    } else {
      alert("Le lien d'invitation n'est pas disponible pour ce serveur.");
    }
  };

  const handleFavoriteToggle = () => {
    if (favorite) {
      // Supprimer des favoris
      api.delete(`/favorites/${server.id}`)
        .then(() => {
          setFavorite(false);
          setCurrentServer((prevServer) => ({
            ...prevServer,
            favoritesCount: Math.max((prevServer.favoritesCount || 1) - 1, 0),
          }));
          if (refreshFavorites) refreshFavorites();
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression des favoris :", error);
        });
    } else {
      // Ajouter aux favoris
      api.post(`/favorites/${server.id}`)
        .then(() => {
          setFavorite(true);
          setCurrentServer((prevServer) => ({
            ...prevServer,
            favoritesCount: (prevServer.favoritesCount || 0) + 1,
          }));
          if (refreshFavorites) refreshFavorites();
        })
        .catch((error) => {
          console.error("Erreur lors de l'ajout aux favoris :", error);
        });
    }
  };

  useEffect(() => {
    if (server && Array.isArray(server.categories)) {
      const selectedCategories = server.categories
        .map((categoryValue) =>
          categoryOptions.find((option) => option.value === categoryValue)
        )
        .filter(Boolean); // Filtrer les valeurs nulles
      setCategories(selectedCategories);
    } else {
      setCategories([]);
    }
  }, [server, categoryOptions]);

  useEffect(() => {
    if (server && Array.isArray(server.countries)) {
      const selectedCountries = server.countries
        .map((countryValue) =>
          countryOptions.find((option) => option.value === countryValue)
        )
        .filter(Boolean); // Filtrer les valeurs nulles
      setCountries(selectedCountries);
    } else {
      setCountries([]);
    }
  }, [server, countryOptions]);

  useEffect(() => {
    setFavorite(isFavorite);
  }, [isFavorite]);

  const { data: user } = useQuery({
    queryKey: ["user"],
    queryFn: () => api.get("/auth/user").then((res) => res.data),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const isUserLoggedIn = !!user;

  const { data: currentUserData } = useQuery(["currentUser"], () =>
    api
      .get("/auth/user")
      .then((res) => res.data)
      .catch(() => null)
  );

  useEffect(() => {
    if (currentUserData) {
      setCurrentUser(currentUserData);
    }
  }, [currentUserData]);

  // Peut-on éditer ce serveur ?
  const canEdit = isDashboard && (userPermissions[server.id]?.canEdit ?? false);

  useEffect(() => {
    setCurrentServer(server);
  }, [server]);

  useEffect(() => {
    if (descriptionRef.current) {
      setTimeout(() => {
        const overflowing =
          descriptionRef.current.scrollHeight >
          descriptionRef.current.clientHeight;
        setIsOverflowing(overflowing);
      }, 0);
    }
  }, [server.description, isDescriptionExpanded, description]);

  const onSave = () => {
    const updatedData = {
      categories: categories.map((category) => category.value),
      tags,
      description,
      countries: countries.map((country) => country.value),
    };
    handleUpdate(server.id, updatedData);
  };

  const addTag = () => {
    if (
      tagInput.trim() !== "" &&
      tags.length < maxTags &&
      tagInput.length <= maxCharactersForTags
    ) {
      setTags([...tags, tagInput.trim()]);
      setTagInput("");
    }
  };

  const removeTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const renderStars = () => {
    if (!server.averageRating) return null;
    const rating = parseFloat(server.averageRating);
    const fullStars = Math.floor(rating);
    const halfStar = rating - fullStars >= 0.5;

    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<FaStar key={i} className="text-indigo-400 text-lg" />);
      } else if (i === fullStars + 1 && halfStar) {
        stars.push(<FaStarHalfAlt key={i} className="text-indigo-400 text-lg" />);
      } else {
        stars.push(<FaRegStar key={i} className="text-indigo-400 text-lg" />);
      }
    }
    return (
      <div className="flex items-center space-x-2">
        <div className="flex items-center">
          {stars}
        </div>
        {server.averageRating && (
          <div className="flex items-center space-x-1 text-gray-400">
            <span>{Number(server.averageRating).toFixed(2)}/5</span>
            <span>({server.reviewsCount || 0})</span>
          </div>
        )}
      </div>
    );
  };

  // Définissez des styles personnalisés
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#2D3748', // Couleur de fond adaptée au thème sombre
      borderColor: '#4A5568',
      color: '#FFFFFF',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#2D3748',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#4A5568' : '#2D3748',
      color: '#FFFFFF',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#FFFFFF',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#4A5568',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#FFFFFF',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#A0AEC0',
    }),
  };

  useEffect(() => {
    setFavorite(isFavorite);
  }, [isFavorite]);

  const handleCountryChange = (selectedOptions) => {
    // Filtrer les options pour supprimer les doublons
    const uniqueOptions = selectedOptions.filter(
      (option, index, self) =>
        index === self.findIndex((o) => o.value === option.value)
    );

    // Limiter le nombre maximum de pays sélectionnés
    if (uniqueOptions.length <= 2) {
      setCountries(uniqueOptions);
    } else {
      // Si plus de deux pays sont sélectionnés, ignorer l'ajout
      alert("Vous ne pouvez sélectionner que deux pays maximum.");
    }
  };

  // Configurer moment pour utiliser le français
  moment.locale('fr');

  const lastBumpedAt = server.lastBumpedAt ? new Date(server.lastBumpedAt) : null;

  const timeSinceLastBump = lastBumpedAt
    ? formatDistanceToNow(lastBumpedAt, { locale: fr, addSuffix: true })
    : null;

  return (
    <div className={`server-card bg-gray-800 rounded-lg shadow-md p-4 flex flex-col h-full ${isDashboard ? '' : 'home-card'} relative`}>
      {/* En-tête */}
      <div className="flex items-start">
        <Link to={`/servers/${server.id}`}>
          <img
            className="h-16 w-16 md:h-20 md:w-20 object-cover rounded-lg border-2 border-gray-700"
            src={imageUrl}
            alt={server.name}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "";
            }}
          />
        </Link>

        <div className="ml-4 flex-1">
          <Link to={`/servers/${server.id}`} className="mt-4">
            <h2 className="text-xl md:text-2xl font-semibold">{server.name}</h2>
          </Link>

          {categories.length > 0 && (
            <div className="flex flex-wrap mt-2">
              {categories.slice(0, 2).map((category, index) => (
                <span
                  key={index}
                  className="bg-indigo-500 text-white py-1 px-3 rounded-full text-sm mr-2 mb-2"
                >
                  {category.label}
                </span>
              ))}
            </div>
          )}

          {tags.length > 0 && (
            <div className="absolute top-13 left-5">
              {tags.slice(0, 2).map((tag, index) => (
                <span
                  key={index}
                  className="bg-gray-700 text-white py-1 px-2 rounded mr-2 mb-2 text-xs"
                >
                  #{tag}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Description */}
      <div className="mt-2 relative text-sm text-gray-300 flex-1">
        <div
          className={`${isDescriptionExpanded ? '' : 'max-h-24 overflow-hidden'}`}
          ref={descriptionRef}
        >
          <p className="leading-5 text-justify whitespace-pre-line">
            {description}
          </p>
        </div>
        {isOverflowing && (
          <button
            className="text-xs text-indigo-400 mt-2 hover:underline block"
            onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
          >
            {isDescriptionExpanded ? t('see_less') : t('see_more')}
          </button>
        )}
      </div>

      {isDashboard && canEdit && (
        <>
          <hr className="border-gray-700 my-4" />
          {/* Édition catégories, tags, description, pays (section admin) */}
          <div className="mt-4 text-sm">
            <p className="font-semibold mb-2">{t("categories")} (max {maxCategories})</p>
            <Select
              styles={customStyles}
              options={categoryOptions}
              value={categories}
              onChange={setCategories}
              isMulti
              placeholder={t("select_categories")}
              className="mt-4"
              maxSelected={maxCategories}
              maxSelectedMessage={t("max_selected_categories")}
              bg="gray-700"
              color="white"
              borderRadius="md"
              borderColor="gray-600"
              borderWidth="1px"
              borderStyle="solid"
            />
            {/* Tags */}
            <p className="font-semibold mt-4 mb-2">{t("tags")} ({tags.length}/{maxTags})</p>
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                placeholder={t("enter_tag")}
                className="flex-1 p-2 bg-gray-700 rounded text-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <button
                onClick={addTag}
                disabled={
                  tagInput.trim() === "" ||
                  tags.length >= maxTags ||
                  tagInput.length > maxCharactersForTags
                }
                className="bg-indigo-600 hover:bg-indigo-500 text-white py-1 px-3 rounded disabled:opacity-50 text-sm"
              >
                {t("add")}
              </button>
            </div>
            <div className="flex flex-wrap mt-3">
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className="bg-gray-700 text-white py-1 px-2 rounded mr-2 mb-2 flex items-center space-x-1"
                >
                  <span>{tag}</span>
                  <button
                    onClick={() => removeTag(index)}
                    className="text-red-400 hover:text-red-300 text-sm font-bold"
                  >
                    &times;
                  </button>
                </span>
              ))}
            </div>

            {/* Description */}
            <p className="font-semibold mt-4 mb-2">{t("description")} ({description.length}/{maxDescription})</p>
            <textarea
              className="w-full bg-gray-700 text-white p-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
              rows="3"
              value={description}
              onChange={(e) => {
                if (e.target.value.length <= maxDescription) {
                  setDescription(e.target.value);
                }
              }}
            ></textarea>

            <Select
              styles={customStyles}
              options={countryOptions}
              value={countries}
              onChange={handleCountryChange}
              isMulti
              placeholder={t("select_countries")}
              className="mt-4"
              closeMenuOnSelect={false}
            />
          </div>
        </>
      )}

      {/* Métriques & Note */}
      <hr className="border-gray-700 my-4" />
      <div className="grid grid-cols-2 gap-4 text-sm text-gray-300 mb-4 md:flex md:flex-wrap md:justify-around">
        <Tooltip text={t('active_writers')}>
          <div className="flex items-center space-x-1">
            <FaPenFancy className="text-green-400 text-xl" />
            <p className="text-lg">
              {server.active7d !== null ? server.active7d : "n/a"}
            </p>
          </div>
        </Tooltip>
        <Tooltip text={t('voice_members')}>
          <div className="flex items-center space-x-1">
            <FaMicrophoneAlt className="text-yellow-400 text-xl" />
            <p className="text-lg">{server.voiceMemberCount || 0}</p>
          </div>
        </Tooltip>
        <Tooltip text={t('voice_channels')}>
          <div className="flex items-center space-x-1">
            <FaHeadset className="text-blue-400 text-xl" />
            <p className="text-lg">{server.activeVoiceChannelsCount || 0}</p>
          </div>
        </Tooltip>
        <Tooltip text={t('online_members')}>
          <div className="flex items-center space-x-1">
            <FaUserFriends className="text-purple-400 text-xl" />
            <p className="text-lg">{server.onlineMemberCount || 0}</p>
          </div>
        </Tooltip>
        <Tooltip text={t('total_members')}>
        <div className="flex items-center space-x-1">
          <FaUsers className="text-indigo-400 text-xl" />
          <p className="text-lg">{server.memberCount}</p>
        </div>
        </Tooltip>
        <Tooltip text={`Flux mensuel : ${server.netChange} (+${server.membersJoined || 0} | -${server.membersLeft || 0})`}>
          <div className="flex items-center space-x-1">
            {server.netChange > 0 ? (
              <FaArrowUp className="text-green-400 text-xl" />
            ) : server.netChange < 0 ? (
              <FaArrowDown className="text-red-400 text-xl" />
            ) : (
              <FaEquals className="text-gray-400 text-xl" />
            )}
            <p className="text-lg">{server.netChange}</p>
          </div>
        </Tooltip>
      </div>

      {/* Note moyenne et étoiles sur la même ligne */}
      <div className="flex items-center justify-between text-sm mb-4">
        {renderStars()}
        {currentServer.countries && currentServer.countries.length > 0 && (
          <div className="flex items-center space-x-2">
            {currentServer.countries.map((countryCode, index) => (
              countryCode ? (
                <Flag
                  key={index}
                  code={countryCode}
                  className="w-6 h-4"
                />
              ) : null
            ))}
          </div>
        )}
      </div>

      {/* Actions */}
      <div className="flex flex-wrap justify-between items-center mt-auto">
        {server.inviteLink ? (
          <button
            className="bg-indigo-600 hover:bg-indigo-500 text-white py-2 px-4 rounded text-sm font-semibold"
            onClick={handleJoinServer}
          >
            {t("join_server")}
          </button>
        ) : (
          <p className="text-sm text-gray-500">
            {t("invite_unavailable")}
          </p>
        )}

        <div className="flex items-center space-x-4">
          {isDashboard && canEdit && (
            <>
              <button
                className="bg-green-600 hover:bg-green-500 text-white py-2 px-4 rounded text-sm font-semibold"
                onClick={onSave}
              >
                {t("save")}
              </button>
              {handleDelete && (
                <button
                  className="bg-red-600 hover:bg-red-500 text-white py-2 px-4 rounded text-sm font-semibold"
                  onClick={() => handleDelete(server.id)}
                >
                  {t("delete")}
                </button>
              )}
            </>
          )}
          {isAdmin && !isDashboard && (
            <button
              className="bg-red-600 hover:bg-red-500 text-white py-2 px-4 rounded text-sm font-semibold"
              onClick={() => handleDelete(server.id)}
            >
              {t("delete")}
            </button>
          )}
          {!isDashboard && !isAdmin && (
            <>
              {isUserLoggedIn && (
                <div className="flex items-center space-x-2">
                  <button onClick={handleFavoriteToggle} className="favorite-button">
                    {favorite ? <FaHeart className="text-pink-500" /> : <FaRegHeart className="text-gray-300" />}
                  </button>
                  <p className="text-sm text-gray-300">
                    ({currentServer.favoritesCount || 0})
                  </p>
                </div>
              )}
              {!currentUser && (
                <button
                  className="text-gray-200 text-2xl"
                  onClick={() => navigate("/login")}
                >
                  ♡
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {/* Afficher le temps écoulé depuis le dernier bump */}
      <div className="server-bump-info">
        {lastBumpedAt ? (
          <span>Up {timeSinceLastBump}</span>
        ) : (
          <span>Aucun up récent</span>
        )}
      </div>
    </div>
  );
}

export default ServerCard;
