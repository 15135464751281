import React, { useEffect, useState } from "react";
import api from "../api";
import ServerCard from "./ServerCard";
import { useTranslation } from "react-i18next";

function ServerList() {
  const { t } = useTranslation();
  const [servers, setServers] = useState([]);

  useEffect(() => {
    fetchServers();
  }, []);

  const fetchServers = async () => {
    try {
      const res = await api.get('/servers');
      console.log('Données reçues :', res.data);
      setServers(res.data.servers || res.data || []);
    } catch (error) {
      console.error('Erreur lors de la récupération des serveurs :', error);
      setServers([]);
    }
  };

  const handleDelete = async (serverId) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce serveur ?")) {
      try {
        await api.delete(`/servers/${serverId}`);
        setServers((prevServers) =>
          prevServers.filter((server) => server.id !== serverId)
        );
      } catch (error) {
        console.error("Erreur lors de la suppression du serveur :", error);
      }
    }
  };

  return (
    <div>
      <h2>Liste des serveurs</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {servers.length > 0 ? (
          servers.map((server) => (
            <ServerCard
              key={server.id}
              server={server}
              handleDelete={handleDelete}
              isAdmin={true}
            />
          ))
        ) : (
          <p>Aucun serveur disponible.</p>
        )}
      </div>
    </div>
  );
}

export default ServerList;
