import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import api from "../api";

function AdminPanel() {
  const navigate = useNavigate();
  const { data: user, isLoading } = useQuery('currentUser', () =>
    api.get('/auth/user').then((res) => res.data)
  );

  if (isLoading) {
    return <p>Chargement...</p>;
  }

  if (!user || (!user.isAdmin && !user.isModerator)) {
    return <p>Accès refusé. Vous n'avez pas les permissions nécessaires.</p>;
  }

  return (
    <div className="text-center p-6">
      <h1 className="text-3xl font-bold mb-8">Panneau d'Administration</h1>

      <div className="flex flex-col items-center space-y-4">
        <button
          onClick={() => navigate("/admin/moderators-management")}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
        >
          Gestion des Modérateurs
        </button>

        <button
          onClick={() => navigate("/admin/servers-list")}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
        >
          Liste des Serveurs
        </button>

        <button
          onClick={() => navigate("/admin/users-list")}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
        >
          Liste des Utilisateurs
        </button>

        <button
          onClick={() => navigate("/admin/logs")}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
        >
          Logs d'Activité
        </button>
      </div>
    </div>
  );
}

export default AdminPanel;
